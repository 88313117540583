/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                $(function() {
           
                });
            },
            finalize: function () {
                // Toggle Menu
                var header = $('.o-header');
                var body = $('body');
                header.on('click', '.j-menu-toggle', function () {
                    header.toggleClass('s-menu-open');
                    body.toggleClass('s-menu-open');
                });
                header.find('.menu-slide').on('click',function(e){
                    e.preventDefault();
                    e.stopPropagation();
                    if($(window).innerWidth() < 981 ){
                        $(this).toggleClass('open').parent().toggleClass('open');
                        $(this).siblings('.sub-menu').stop(true, true).slideToggle(200);
                    }
                });
                window.PageFunctions.stickyHeader();
                window.PageFunctions.accordion();
                window.PageFunctions.testimonial();
                window.PageFunctions.brands();
                window.PageFunctions.serviceCarousel();



                var controller = new ScrollMagic.Controller();
                var triggers = $('.p-home .parallax-object');
                triggers.each(function(index){
                    trigger = triggers.get(index);
                    var tweenOut = new TimelineMax()
                    .add([
                        TweenMax.fromTo(trigger, 1, {'transform': 'translateY(0px)' }, {'transform': 'translateY(-100px)' })
                    ]);
                    var sceneOut = new  ScrollMagic.Scene({ triggerElement: trigger, duration: $(trigger).height(), triggerHook: 0  })
                    .setTween(tweenOut)
                    // .addIndicators()
                    .addTo(controller);

                    var tweenIn = new TimelineMax()
                    .add([
                        TweenMax.fromTo(trigger, 1, {'transform': 'translateY(300px)' }, {'transform': 'translateY(0)' })
                    ]);
                    var sceneIn = new  ScrollMagic.Scene({ triggerElement: trigger, duration: $(window).height(), triggerHook: 1  })
                    .setTween(tweenIn)
                    // .addIndicators()
                    .addTo(controller);
                });
               
            }
        },
        // Home page
        'page_template_brochure': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {

                var controller = new ScrollMagic.Controller();
                var triggers = $('.parallax-object');
                triggers.each(function(index){
                    trigger = triggers.get(index);
                    var tweenIn = new TimelineMax()
                    .add([
                        TweenMax.fromTo(trigger, 1, {'transform': 'translateY(0px)' }, {'transform': 'translateY(250px)' })
                    ]);
                    var sceneIn = new  ScrollMagic.Scene({ triggerPosition: 0, duration: $(window).height(), triggerHook: 1  })
                    .setTween(tweenIn)
                    // .addIndicators()
                    .addTo(controller);
                });
            }
        },

    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

