(function($, window, document) { // jQuery wrapper

    // Page Functions
    window.PageFunctions = {

        menu: "",
        menuToggle: "",


        stickyHeader: function(){
            // Sticky Header
            var header = $('.o-header');
            function checkHeaderPosition(){
                var scroll = $(window).scrollTop();
                if (scroll >= 50) {
                    header.addClass('o-header__scrolled is_sticky');
                } else {
                    if (scroll <= 5) {
                        header.removeClass('o-header__scrolled is_sticky');
                    }
                }
            }
            checkHeaderPosition();
            //caches a jQuery object containing the header element
            $(window).scroll(function() {
                checkHeaderPosition();
            });

        },

        // Smooth scroll on page anchor links.
        // Will scroll to the top for # links.
        smoothScroll : function (){
            $('a[href^="#"]').click(function () {
                console.log('scrolling');
                var scrollTarget;
                // If the link is just # set scrollTarget to top of page
                if($(this).attr('href') === '#'){
                    scrollTarget = 0; //
                }else{ // Otherwise locate the target element and find its offset from the top
                    if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
                        var target = $(this.hash);
                        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                        if (target.length) {
                            scrollTarget =  target.offset().top;
                        }
                    }
                }
                // Scroll the page to the target.
                $('html, body').animate({
                    scrollTop: scrollTarget
                }, 1000);
                return false; // Exit.
            });
        },
        testimonial: function(){
            $('.j-testimonials').slick({
                arrows: false,
                dots: true,
            });
        },
        accordion: function(){
            $('.j-accordion').on('click','.j-accordion--toggle',function(){
                var _this = $(this);
                // var accordion = _this.parents('.j-accordion');
                var single =  _this.parents('.j-accordion--single');
                single.toggleClass('is__open').find('.j-accordion--body').slideToggle();
                single.siblings('.is__open').removeClass('is__open').find('.j-accordion--body').slideUp();


                if(single.hasClass('is__open')){
                    // Scroll the page to the target.
                    setTimeout(function(single){
                        var  scrollTarget =  single.offset().top;
                        $('html, body').animate({
                            scrollTop: scrollTarget - 105
                        }, 250);
                    }, 500, single);
                }
            });
        },

        brands: function(){
            $('.j-brands').slick({
                arrows: true,
                dots: false,
                // infinite: false, //
                speed: 300,
                slidesToShow: 8,
                slidesToScroll: 8,
                // autoSlidesToShow: true,
                // variableWidth: true,
                prevArrow: $('.o-brands--prev'),
                nextArrow: $('.o-brands--next'),

                responsive: [
                    {
                      breakpoint: 1200,
                      settings: {
                        slidesToShow: 7,
                        slidesToScroll: 7,
                      }
                    },
                    {
                      breakpoint: 1100,
                      settings: {
                        slidesToShow: 6,
                        slidesToScroll: 6,
                      }
                    },
                    {
                      breakpoint: 900,
                      settings: {
                        slidesToShow: 5,
                        slidesToScroll: 5,
                      }
                    },
                    {
                      breakpoint: 700,
                      settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                      }
                    },
                    {
                      breakpoint: 600,
                      settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                      }
                    },
                    {
                      breakpoint: 480,
                      settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                      }
                    }
                ]
            });
        },
        serviceCarousel: function(){
            var carousel = $('.j-services-carousel').slick({
                arrows: true,
                dots: false,
                fade: true,
                appendArrows: $('.o-services-carousel--nagivation'),
                nextArrow: '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.48438 2.41421C7.48438 1.52331 8.56152 1.07714 9.19148 1.70711L15.2625 7.77817C15.6531 8.1687 15.6531 8.80186 15.2625 9.19239L9.19148 15.2635C8.56152 15.8934 7.48438 15.4473 7.48438 14.5563V2.41421Z" fill="#5F5754"/></svg>',
                prevArrow: '<svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.48633 2.41421C8.48633 1.52331 7.40919 1.07714 6.77922 1.70711L0.708154 7.77817C0.317629 8.1687 0.317629 8.80186 0.708154 9.19239L6.77922 15.2635C7.40919 15.8934 8.48633 15.4473 8.48633 14.5563V2.41421Z" fill="#5F5754"/></svg>',
                responsive:[
                    {
                        breakpoint: 768,
                        settings: {
                            adaptiveHeight: true,
                        }
                    },
                ]
            }).on('beforeChange',function(event, slide, currentSlide, nextSlide){
                $('.j-services-carousel--toggle').removeClass('active');
                $('.j-services-carousel--toggle[data-slick="' + nextSlide + '"]').addClass('active');
            });
            $('.j-services-carousel--toggle').on('click',function(){
                carousel.slick('slickGoTo', $(this).data('slick'));

                scrollTarget =  carousel.offset().top;
                // Scroll the page to the target.
                $('html, body').animate({
                    scrollTop: scrollTarget - 150
                }, 500);
                return false; // Exit.
                               

            });
        },
    };

}(window.jQuery, window, document)); // End of jQuery wrapper